<template>
  <div class="warehousing">
    <!-- 標題 -->
    <div class="single-page-tag">
      <p class="sp-start sp-text fw-bolder">現貨入庫與限量</p>
    </div>
    <!-- 內容 -->
    <div class="tw-container shadow border border-1 border-dark mt-4">
      <div class="mb-4" style="height: calc(100vh - 200px)">
        <!-- 標頭 -->
        <div class="mb-2">
          <!-- 標題 -->
          <h1 class="tw-border-start fw-bolder tw-text-size20 ms-3 my-3">
            商品名稱：{{ stockList.name }}
            <span
              class="text-success"
              v-if="merchandiseInfo.sellState === 'CANPREORDER'"
              >(可預購)</span
            >
            <span
              class="text-primary"
              v-else-if="merchandiseInfo.sellState === 'STOCKONLY'"
              >(僅供現貨)</span
            >
            <span class="text-danger" v-else>(限量)</span>
          </h1>
          <!-- 搜尋 -->
          <div class="input-group my-2">
            <input
              type="text"
              class="form-control"
              placeholder="輸入欲搜尋款式"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              v-model="search"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              @click="merchandiseStyleSearch"
            >
              <i class="bi bi-search"></i>
            </button>
          </div>
        </div>
        <!-- 表格 -->
        <div
          class="table-responsive"
          style="height: calc(100vh - 250px); overflow-y: scroll"
        >
          <table class="table table-hover" width="50%">
            <thead class="float-header">
              <tr>
                <th style="min-width: 100px">入庫款式</th>
                <th style="min-width: 80px">成本</th>
                <th style="min-width: 80px">庫存</th>
                <th style="min-width: 80px">可配數</th>
                <th style="min-width: 100px">僅供現貨數</th>
                <th style="min-width: 100px">入庫數量</th>
                <th style="min-width: 100px">目前限量數</th>
                <th style="min-width: 100px">限量數量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in merchandiseStylesStocksSearch" :key="item.id">
                <td>{{ item.name.join() }}</td>
                <td>{{ item.cost }}</td>
                <td>{{ item.quantity }}</td>
                <td>
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="item.availableFromStockQuantity === null"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <p v-else>{{ item.availableFromStockQuantity }}</p>
                </td>
                <td>{{ item.stockOnlyQuantity }}</td>
                <td>
                  <input
                    onwheel="this.blur()"
                    type="number"
                    min="0"
                    class="form-control d-inline-block"
                    placeholder="輸入入庫數量"
                    v-model="item.stockQty"
                  />
                </td>
                <td>{{ item.currentLimitedQuantity }}</td>
                <td>
                  <input
                    onwheel="this.blur()"
                    type="number"
                    min="0"
                    class="form-control d-inline-block"
                    placeholder="輸入限量數量"
                    v-model="item.limitedQty"
                    v-if="merchandiseInfo.sellState === 'LIMITED'"
                  />
                  <p class="text-danger fw-bolder" v-else>
                    請先將販售狀態改為"限量"才能調整喔!
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- 按鈕 -->
      <div class="text-end">
        <router-link
          :to="`/seller/store/${storeId}/merchandise`"
          class="tw-btn tw-btn-secondary me-2"
          >返回</router-link
        >
        <button class="tw-btn tw-btn-success me-2" @click="createStock(true)">
          入庫並配單
        </button>
        <button class="tw-btn tw-btn-success" @click="createStock(false)">
          確認
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // data
      serverToken: '',
      storeId: 0,
      merchandiseId: 0,
      merchandiseInfo: {},
      stockList: {},
      merchandiseStylesStocksSearch: [],
      search: '',
    }
  },
  created() {
    this.initialization()
    this.getMerchandiseStylesStockInfo()
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
    },
    // 取得商品款式庫存資訊
    getMerchandiseStylesStockInfo() {
      const vm = this
      this.$methods.switchLoading('show')
      const getMerchandiseStylesStockInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const token = this.serverToken;
      const header = {
        authorization: token,
      };
      this.merchandiseId = this.$route.query.id ? this.$route.query.id : null
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{generateMerchandiseStylesStockJsonNode}'
        },
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseStylesStockInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects
            vm.merchandiseInfo = merchandiseInfo[0]
            vm.stockMap(merchandiseInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理庫存列表
    stockMap(merchandiseInfo) {
      console.log(merchandiseInfo)
      merchandiseInfo.forEach(item => {
        const object = {
          arrivalTime: item.arrivalTime,
          checkOut: item.checkOut,
          createTime: item.createTime,
          deadLineTime: item.deadLineTime,
          deleted: item.deleted,
          description: item.description,
          id: item.id,
          label: item.label,
          merchandiseStylesStocks: [],
          merchandiseStylesStockJsonNode: item.merchandiseStylesStockJsonNode,
          name: item.name,
          note: item.note,
          number: item.number,
          ready: item.ready,
          sellState: item.sellStat,
          startSaleQuantity: item.startSaleQuantity,
          stocks: item.stocks,
          storageLocation: item.storageLocation,
          storeId: item.storeId,
          storeVipId: item.storeVipId,
        }
        // 需特別處理
        // 商品款式
        item.merchandiseStylesStockJsonNode.forEach(data => {
          const merchandiseStylesStocks = {
            availableFromStockQuantity: data.stock ? data.stock.availableFromStockQuantity : 0,
            id: new Date() * 1,
            quantity: data.stock ? data.stock.quantity : 0,
            limitedQuantity: data.stock ? data.stock.limitedQuantity : 0,
            stockOnlyQuantity: data.stock ? data.stock.stockOnlyQuantity : 0,
            merchOrderQuantity: data.stock ? data.stock.merchOrderQuantity : 0,
            currentLimitedQuantity: 0,
            cost: data.styles[0].cost,
            stockId: data.stock ? data.stock.id : null,
            stockQty: 0,
            limitedQty: 0,
            merchandiseStyleIds: [],
            name: []
          }
          merchandiseStylesStocks.currentLimitedQuantity = Math.max(merchandiseStylesStocks.limitedQuantity - merchandiseStylesStocks.merchOrderQuantity, 0)
          data.styles.forEach(style => {
            merchandiseStylesStocks.merchandiseStyleIds.push(style.id)
            merchandiseStylesStocks.name.push(style.name)
          })
          object.merchandiseStylesStocks.push(merchandiseStylesStocks)
        })
        this.stockList = JSON.parse(JSON.stringify(object))
      })
      this.merchandiseStylesStocksSearch = this.stockList.merchandiseStylesStocks
      this.$methods.switchLoading('hide')
      this.dataTabelLoading = false
    },
    // 建立庫存
    createStock(allocate) {
      const vm = this;
      this.$methods.switchLoading('show')
      const addStockQuantityApi = `${process.env.VUE_APP_API}/stock/update`;
      const token = this.serverToken;
      const header = {
        authorization: token,
      };
      const data = []
      this.stockList.merchandiseStylesStocks.forEach(item => {
        item.stockQty = item.stockQty ? item.stockQty : 0
        item.limitedQty = item.limitedQty ? item.limitedQty : 0
        if (item.stockQty !== 0 || item.limitedQty !== 0) {
          const obj = {
            stock: {
              quantity: item.quantity + item.stockQty,
              limitedQuantity: item.limitedQuantity + item.limitedQty
            },
            allocate: allocate
          }
          if (!item.stockId) obj.merchandiseStyleIds = item.merchandiseStyleIds
          else obj.stock.id = item.stockId
          data.push(obj)
        }
      })
      console.log(data)
      if (data.length === 0 ) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert("other", "您尚未輸入任何數量");
      }
      $.ajax({
      type: 'PUT',
      async: true,
      url: addStockQuantityApi,
      headers: header,
      data: JSON.stringify(data),
      contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.goBack()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.sortoutErrorMsg(err.responseJSON)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 處理錯誤訊息
    sortoutErrorMsg(err) {
      if (err.errorData.length > 0) {
        const obj = {
          icon: 'warning',
          title: {
            show: true,
            value: '錯誤'
          },
          text: {
            show: true,
            value: ''
          },
          timer: 3000,
          showConfirmButton: false
        }
        err.errorData.forEach(item => {
          obj.text.value = item.message
        });
        this.SweetAlert('setByMyself', obj)
      } else this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
    },
    // 搜尋款式
    merchandiseStyleSearch() {
      this.merchandiseStylesStocksSearch = this.stockList.merchandiseStylesStocks.filter(item => {
        return item.name.join().match(this.search)
      })
    },
    // 返回
    goBack() {
      this.$router.push(`/seller/store/${this.storeId}/merchandise`)
    },
  }  
}
</script>